import { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert
} from 'react-bootstrap';

import { handleEmailAction } from './Toolbox/MailAction';
import { NormalSignUp, NormalSignIn } from './Toolbox/NormalAccountAction';


const SignUpComp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    return regex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('読み込み中...');
    setIsLoading(true);

    if (!email || !password || !passwordConfirm) {
      setError('全てのフィールドを入力してください');
      setMessage('');
      return;
    }

    if (password !== passwordConfirm) {
      setError('パスワードが一致しません');
      return;
    }

    if (!validatePassword(password)) {
      setError('パスワードは6文字以上の英数字を含む必要があります');
      return;
    }

    const response = await NormalSignUp(email, password);
    if (response.success) {
      setIsLoading(false);
      setMessage(response.message);
    } else {
      setIsLoading(false);
      setError(response.message);
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col xs={12} md={6}>
          <h2 className="mb-4">新規登録</h2>
          {message && <Alert variant="success" role="alert">{message}</Alert>}
          {error && <Alert variant="danger" role="alert">{error}</Alert>}
          <Form onSubmit={handleSubmit} noValidate>
            <Form.Group className="mb-3" controlId="userEmail">
              <Form.Label>メールアドレス</Form.Label>
              <Form.Control
                type="email"
                placeholder="メールアドレスを入力"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="userPassword">
              <Form.Label>パスワード</Form.Label>
              <Form.Control
                type="password"
                placeholder="パスワードを入力"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <Form.Text className="text-muted">
                6文字以上の英数字で入力してください
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="userPasswordConfirm">
              <Form.Label>パスワード（確認）</Form.Label>
              <Form.Control
                type="password"
                placeholder="パスワードを再度入力"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                required
              />
            </Form.Group>

            <Button 
              variant="secondary" 
              type="submit"
              disabled={isLoading}
              >
              登録
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};


const VerifiedEmailComp = () => {
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const verifyEmail = async () => {
      const { ActionCode, mode } = getURLParams();
      try {
        await handleEmailAction(ActionCode, mode);
        setMessage('メールアドレスの認証が完了しました');
        setTimeout(() => {
          window.location.href = '/';
        }, 3000);
      } catch (error) {
        setErrorMessage(error.message || '認証中にエラーが発生しました');
      }
    };

    verifyEmail();
  }, []);

  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col xs={12} md={6}>
          {errorMessage ? (
            <Alert variant="danger" role="alert">
              {errorMessage}
            </Alert>
          ) : (
            <Alert variant="success" role="status">
              {message}
            </Alert>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const LoginComp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    if (!email || !password) {
      setError('全てのフィールドを入力してください');
      return;
    }

    const response = await NormalSignIn(email, password);
    if (response.success) {
      setIsLoading(false);
      setMessage(response.message);
      setTimeout(() => {
        window.location.href = '/';
      }, 3000);
    } else {
      setError(response.message);
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col xs={12} md={6}>
        <h2 className="mb-4">ログイン</h2>
          {message && <Alert variant="success" role="alert">{message}</Alert>}
          {error && <Alert variant="danger" role="alert">{error}</Alert>}
          <Form onSubmit={handleSubmit} noValidate>
              <Form.Group className="mb-3" controlId="userEmail">
                <Form.Label>メールアドレス</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="メールアドレスを入力"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="userPassword">
                <Form.Label>パスワード</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="パスワードを入力"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <Form.Text className="text-muted">
                  6文字以上の英数字
                </Form.Text>
              </Form.Group>

              <Button 
              variant="secondary" 
              type="submit" 
              disabled={isLoading}
              >
                ログイン
              </Button>
            </Form>
        </Col>
      </Row>
    </Container>
  )
}

function getURLParams() {
  const urlParams = new URLSearchParams(window.location.search);
  const ActionCode = urlParams.get('oobCode');
  const mode = urlParams.get('mode');

  return { ActionCode, mode };
};


export { SignUpComp, VerifiedEmailComp, LoginComp };