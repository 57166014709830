// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBqbp7Ty9ZQVbR_LAcHk1FcNooej-u42aU",
  authDomain: "english-sentence-collector.firebaseapp.com",
  projectId: "english-sentence-collector",
  storageBucket: "english-sentence-collector.appspot.com",
  messagingSenderId: "123674710045",
  appId: "1:123674710045:web:a1e699228c6d30fdacb6f4",
  measurementId: "G-5DX7Z6G7MC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { app, auth };