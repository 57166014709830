import { 
    getAuth,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    signInWithEmailAndPassword
} from 'firebase/auth';
import { app } from '../../firebase';

const auth = getAuth(app);

//　Normal sign up process
// receive email and password varedated
export async function NormalSignUp(email, password) {
    try {
        let userCredential = await createUserWithEmailAndPassword(auth, email, password);
        let user = userCredential.user;
        await sendEmailVerification(user);
        return {
            success: true,
            message: "確認メールを送信しました。メールを確認してください",
            user: user.uid
        };
    } catch (error) {
        let errorMessage;
        switch (error.code) {
            case "auth/email-already-in-use":
                errorMessage = "このメールアドレスは既に使用されています";
                break;
            case "auth/invalid-email":
                errorMessage = "メールアドレスの形式が正しくありません";
                break;
            case "auth/weak-password":
                errorMessage = "パスワードは6文字以上で入力してください";
                break;
            default:
                errorMessage = "予期しないエラーが発生しました。もう一度やり直してください";
                break;
        }
       
        return {
            success: false,
            message: errorMessage
        }
    }
}

export async function NormalSignIn(email, password) {
    try {
        let userCredential = await signInWithEmailAndPassword(auth, email, password);
        let user = userCredential.user;
        if (!user.emailVerified) {
            await sendEmailVerification(user);
            console.log("Loged in")
            return {
                success: false,
                message: "メールアドレスの認証が完了していません。再度メールを送信しました"
            }
        }
        return {
            success: true,
            message: "ログインしました",
            user: user.uid
        };
    } catch(error) {
        let errorMessage;
        switch (error.code) {
            case "auth/invalid-email":
                errorMessage = "メールアドレスの形式が正しくありません";
                break;
            case "auth/user-disabled":
                errorMessage = "アカウントが無効化されています";
                break;
            case "auth/user-not-found":
                errorMessage = "ユーザーが見つかりません";
                break;
            case "auth/wrong-password":
                errorMessage = "パスワードが間違っています";
                break;
            default:
                errorMessage = "予期しないエラーが発生しました。もう一度やり直してください";
                break;
        }
        return {
            success: false,
            message: errorMessage
        }
    }
}

export async function ConfirmCurrentUser() {
    let user = auth.currentUser;
    return user;
}