import {
    getAuth,
    applyActionCode,
    verifyPasswordResetCode
} from 'firebase/auth';
import { app } from '../../firebase';

const auth = getAuth(app);

export async function handleEmailAction(mode, oobCode) {
    let result;
    switch (mode) {
        case 'verifyEmail':
            result = await verifyEmail(oobCode);
            return result
        case 'resetPassword':
            result = await ConfirmPasswordResetCode(oobCode);
            return result
        default:
            console.error(`Unexpected mode: ${mode}`);
            return {
                success: false,
                message: "予期しないエラーが発生しました。もう一度やり直してください"
            };
    }
}

async function verifyEmail(oobCode) {
    try {
        await applyActionCode(auth, oobCode);
        return {
            success: true,
            message: "メールアドレスの認証が完了しました"
        };
    } catch(error) {
        return {
            success: false,
            message: getErrorMessage(error)
        };
    }
}

async function ConfirmPasswordResetCode(oobCode) {
    try {
        await verifyPasswordResetCode(auth, oobCode);
        return {
            success: true,
            message: "認証が完了しました。３秒後にパスワードの再設定ページに遷移します。"
        };
    } catch(error) {
        return {
            success: false,
            message: getErrorMessage(error)
        };
    }
}


function getErrorMessage(error) {
    switch (error.code) {
        case "auth/expired-action-code":
            return "リンクの有効期限が切れています。もう一度やり直してください";
        case "auth/invalid-action-code":
            return "リンクが無効です。もう一度やり直してください";
        case "auth/user-disabled":
            return "このユーザーは無効です。メールアドレスを確認してください。";
        case "auth/user-not-found":
            return "ユーザーが見つかりません。メールアドレスを確認してください。";
        case "auth/weak-password":
            return "パスワードが弱すぎます。より強力なパスワードを設定してください。";
        default:
            console.error('Unhandled Firebase Auth error:', error);
            return "予期しないエラーが発生しました。もう一度やり直してください";
    }
}
