import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaUserCircle, FaHome, FaHistory, FaStore, FaCog, FaSignOutAlt } from 'react-icons/fa';
import { SignUpComp, VerifiedEmailComp, LoginComp } from './Auth/NormalAuthUI.js';
import { Header, Auth_Header } from './Base/Header.js';
import CorrectionComp  from './Base/correction.js';


function Home() {
  const credit = 100;
  return (
    <div className="bg-light min-vh-100">
      <Header />
      <Container className="py-5">
        <CorrectionComp />
      </Container>
    </div>
  );
}

function SignUp() {
  return (
    <div className="bg-light min-vh-100">
      <Container className="py-5">
        <Auth_Header />
        <SignUpComp />
      </Container>
    </div>
  );
}

function Login() {
  return (
    <div className="bg-light min-vh-100">
      <Container className="py-5">
        <Auth_Header />
        <LoginComp />
      </Container>
    </div>
  );
}

function MailActionAuth() {
  return (
    <div className="bg-light min-vh-100">
      <Container className="py-5">
        <Auth_Header />
        <VerifiedEmailComp />
      </Container>
    </div>
  );
}


export { Home, SignUp, MailActionAuth, Login };