import React, { useState, useEffect } from 'react';
import { Navbar, Container, Nav, Button } from 'react-bootstrap';
import { FaHome, FaHistory, FaStore, FaCog } from 'react-icons/fa';
import { ConfirmCurrentUser } from '../Auth/Toolbox/NormalAccountAction';

const Header = () => {
    // Confirm current login information
    const [isLogin, setIsLogin] = useState(false);
    useEffect(() => {
        const user = ConfirmCurrentUser();
        if (user == null) {
            setIsLogin(true);
        }
    });

    return (
        <Navbar expand="lg" bg="dark" variant="dark" className="py-2 shadow-sm">
            <Container fluid>
                <Navbar.Brand href="/" className="fs-3 fw-bold">Correcord</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto align-items-center">
                        <Nav.Link href="/" className="mx-2 fs-7"><FaHome className="me-2" />ホーム</Nav.Link>
                        <Nav.Link href="#History" className="mx-2 fs-7"><FaHistory className="me-2" />履歴</Nav.Link>
                        <Nav.Link href="#Store" className="mx-2 fs-7"><FaStore className="me-2" />ストア</Nav.Link>
                        <Nav.Link href="#Setting" className="mx-2 fs-7"><FaCog className="me-2" />設定</Nav.Link>
                        {isLogin ? (
                            <Nav.Link className="me-3">
                                <Button variant="outline-light">
                                    ログアウト
                                </Button>
                            </Nav.Link>
                        ) : (
                            <>
                                <Nav.Link href="/signup" className="mx-2 fs-7">登録</Nav.Link>
                                <Nav.Link href="/login" className="me-3">
                                    <Button variant="outline-light">
                                        ログイン
                                    </Button>
                                </Nav.Link>
                            </>
                        )}
                        
                            
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}


const Auth_Header = () => {
    return (
        <Navbar.Brand href="/" className="fs-3 fw-bold">Correcord</Navbar.Brand>
    )
}
/*

<Nav.Link href="/signup" className="mx-2 fs-7">登録</Nav.Link>
                            <Nav.Link href="/login" className="me-3">
                                <Button variant="outline-light">
                                    ログイン
                                </Button>
                            </Nav.Link>

*/

export { Header, Auth_Header };