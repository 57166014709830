import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card, Alert } from 'react-bootstrap';
import { Send, Plus, Minus } from 'lucide-react';
import { Correction_For_Free } from '../Auth/Toolbox/BackendService';

const CorrectionComp = () => {
  const [textPairs, setTextPairs] = useState([
    {
      original: '',
      translation: '',
      originalLanguage: '',
      translationLanguage: '',
    }
  ]);
  const [corrections, setCorrections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const users_language = 'japanese';
  const uid = 'user1234'; // この値は実際のユーザー認証システムから取得すべきです

  const languages = [
    { value: 'en', label: '英語' },
    { value: 'zh', label: '中国語' },
    { value: 'ja', label: '日本語' },
    { value: 'ko', label: '韓国語' },
    { value: 'fr', label: 'フランス語' },
  ];

  const handleTextChange = (index, field, value) => {
    const updatedPairs = [...textPairs];
    updatedPairs[index][field] = value;
    setTextPairs(updatedPairs);
  };

  const addTextPair = () => {
    setTextPairs([...textPairs, { original: '', translation: '', originalLanguage: '', translationLanguage: '' }]);
  };

  const removeTextPair = (index) => {
    if (textPairs.length > 1) {
      const updatedPairs = textPairs.filter((_, i) => i !== index);
      setTextPairs(updatedPairs);
    }
  };

  const convertToJSON = () => {
    return textPairs.map((pair, index) => ({
      question_index: index + 1,
      original: pair.original,
      translation: pair.translation,
      original_lang: pair.originalLanguage,
      translation_lang: pair.translationLanguage,
      feedback_lang: users_language === 'japanese' ? 'ja' : 'en', // ユーザー言語に基づいてフィードバック言語を設定
      uid: uid
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setCorrections([]);

    try {
        const jsonData = convertToJSON();
        console.log('Sending data:', JSON.stringify(jsonData, null, 2)); // デバッグ用

        // jsonData全体を渡す
        const response = await Correction_For_Free(jsonData);

        if (response.error) {
            throw new Error(response.error); // エラーハンドリング
        }
              
        setCorrections(response); // ここで結果をセット
    } catch (error) {
        setError(error.message || '添削に失敗しました');
    }
    setIsLoading(false);
  };

  const LanguageSelect = ({ label, value, onChange }) => (
    <Form.Select
      aria-label={`${label}の言語`}
      className="mb-3 border-0 bg-light"
      value={value}
      onChange={onChange}
    >
      <option value="">{label}の言語</option>
      {languages.map((lang) => (
        <option key={lang.value} value={lang.value}>
          {lang.label}
        </option>
      ))}
    </Form.Select>
  );

  const renderCorrections = () => {
    return corrections.map((correction, index) => (
      <Card key={index} className="mb-4">
        <Card.Body>
          <Card.Title>添削 #{correction.question_index}</Card.Title>
          <Card.Text>
            <strong>原文:</strong> {correction.original}
          </Card.Text>
          <Card.Text>
            <strong>訳文:</strong> {correction.translation}
          </Card.Text>
          <Card.Text>
            <strong>フィードバック:</strong> {correction.feedback}
          </Card.Text>
        </Card.Body>
      </Card>
    ));
  };

  if (isLoading) {
    return <Container><Alert variant="info">ロード中...</Alert></Container>;
  }

  if (error) {
    return (
      <Container>
        <Alert variant="danger">エラー: {error}</Alert>
      </Container>
    );
  }

  return (
    <Container className="py-1">
      <Card className="shadow-lg mb-4">
        <Card.Body className="p-4">
          <h2 className="text-center mb-4">添削</h2>
          <Form onSubmit={handleSubmit}>
            {textPairs.map((pair, index) => (
              <Row key={index} className="mb-4">
                <Col xs={12} md={6} className="mb-4">
                  <LanguageSelect
                    label="原文"
                    value={pair.originalLanguage}
                    onChange={(e) => handleTextChange(index, 'originalLanguage', e.target.value)}
                  />
                  <Form.Group controlId={`original-${index}`}>
                    <Form.Label className="fw-bold">原文 #{index + 1}</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      value={pair.original}
                      onChange={(e) => handleTextChange(index, 'original', e.target.value)}
                      className="border-0 bg-light"
                      placeholder="原文を入力してください..."
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} className="mb-4">
                  <LanguageSelect
                    label="訳文"
                    value={pair.translationLanguage}
                    onChange={(e) => handleTextChange(index, 'translationLanguage', e.target.value)}
                  />
                  <Form.Group controlId={`translation-${index}`}>
                    <Form.Label className="fw-bold">訳文 #{index + 1}</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      value={pair.translation}
                      onChange={(e) => handleTextChange(index, 'translation', e.target.value)}
                      className="border-0 bg-light"
                      placeholder="訳文を入力してください..."
                    />
                  </Form.Group>
                </Col>
                <Row className="text-end">
                  <Col xs="auto">
                    <Button variant="primary" onClick={() => addTextPair(index)} className="mt-2">
                      <Plus size={20} />
                    </Button>
                  </Col>
                  {index > 0 && (
                    <Col xs="auto">
                      <Button variant="danger" onClick={() => removeTextPair(index)} className="mt-2">
                        <Minus size={20} />
                      </Button>
                    </Col>
                  )}
                </Row>
              </Row>
            ))}
            <Row className="justify-content-center mt-4">
              <Col xs="auto">
                <Button
                  variant="secondary"
                  type="submit"
                  size="lg"
                  className="px-4 py-2 d-flex align-items-center"
                >
                  <Send size={20} className="me-2" />
                  送信
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      
      {corrections.length > 0 && (
        <Card className="shadow-lg">
          <Card.Body>
            <h3 className="text-center mb-4">添削結果</h3>
            {renderCorrections()}
          </Card.Body>
        </Card>
      )}
    </Container>
  );
};

export default CorrectionComp;