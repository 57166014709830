// setting api
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL_FOR_PROD;

export async function Correction_For_Free(data) {
    try {
        const response = await fetch(`${BACKEND_URL}/api/v1/a800cf13-0164-ba02-0e2a-af1e004df4f5/correction/free`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data), // ここでdata全体を渡します
        });

        if (!response.ok) {
            console.log(response);
            throw new Error('添削に失敗しました');
        }

        const result = await response.json();
        
        return result;
        
    } catch (error) {
        console.log(error);
        return { error: error.message || '添削に失敗しました' };
    }
}
